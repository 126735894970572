 .background {
   background-color: #38BDDE;
   display: flex;
   flex-direction: column;
   min-width: 813px;
 }

 .header-container {
   width: 98%;
   margin-left: 1%;
   height: 2%
 }

 .header {
   display: flex;
   flex-direction: column;
   padding: 0%;
   margin: 0%;
 }

 .navMenu {
   padding: 0%;
   width: 100;
   margin: 0%;
 }
 a.navbar-brand {
   white-space: normal;
   text-align: center;
   word-break: break-all;
 }

 html {
   font-size: 14px;
 }

 @media (min-width: 768px) {
   html {
     font-size: 16px;
   }
 }

 .box-shadow {
   box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
 }
@use "variables" as v;

.support-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 75vh;
}

.support-div{
    margin-bottom: 10%;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%;
   padding-top:3%
}

.support-header {
    display: flex;
    font-size: 250%;
    font-weight: lighter;
    width: 70%;
    margin: 3% 3% 0% 20%

}

.support-card {
    width: 60% !important;
    background-color: v.$lightGrey;
    padding: 2%;
    border: 2px solid #d4d4d4;
    border-radius: 5px;
    margin: 1% 10% 5% 20%;
   
}

.support-button{
    float: right;
    width: 30%;
}

@use "variables" as v;

.background-color {
    background-color: #38BDDE;

}

.stopList-container {
    margin: 0px 25px;
    padding-bottom: 0%;
    min-height: 100vh;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%
}

.buttons-container {
    width: 100%;
    display: flex;
    padding: 1%;
    justify-content: center;
}

.route_id{
    margin-left: 2%;
  }
  
#loader {
    margin-top: 200px;
}

#date-picker {
    margin-left: 50px;
}
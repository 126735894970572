@use "variables" as v;

.logs-wrapper {
    min-height: 80vh;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%;

}

#logs-grid {
    margin-bottom: 2.5%;
}

#card {
    width: 100%;
    margin: 3% 0% 3% 3%
}

#logs-card-content {
    margin: 0%;
    padding: 0% 0% 5% 0%
}

.logs-span {
    font-size: 18px;
    background-color: v.$white;
    padding: 2%;
    width: 100%;
    margin: 0% 0% 5% 0%
}

#logs-form {
    display: flex;
    flex-direction: column
}

#logs-form-field {
    width: 90%;
    margin-left: 5%
}

#logs-select {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
}

.apply-button {
    display: flex;
    justify-content: center;
}

#logs-button {
    min-width: 15vw;
    margin: 5% .85% 0% 0%;
    display: flex;
    justify-content: center
}

.logs-section-header {
    display: flex;
    margin: 0%;

}

.logs-entries-container {
    width: 40%;
    float: left;
    margin-left: 1%;

}

#logs-table-header {
    text-align: center;
}


#logs-table-cell {
    text-align: center;
    width: 4%;
    background-color: v.$FG-Blue;
    color: v.$white
}



.logs-dropDown {
    margin-top: 5%
}

.logs-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1%;
    margin-right: 1%;
}

@use "variables" as v;

.reports-container{
    min-height: 75vh
}

.reports-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    background-color: v.$white;
    width: 98%;
    margin-left: 1%;
    padding-bottom: 2.5%
}

.reports-card {
    width: 50% !important;
}
@use "variables" as v;

.App {
	display: flex;
	flex-direction: column;
	width: 100vw;
	
  }
  
  .paginationBttns {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: flex-end;
	align-items: center;

  }
  
  .paginationBttns a {
	padding: 10px;
	border: 1px solid 
	#E3E3E3;
	color: v.$FG-Blue;
	cursor: pointer;
  }
  
  .paginationBttns a:hover {
	color: v.$white;
	background-color: v.$FG-Blue;
  }
  
  .paginationActive a {
	color: v.$white;
	background-color: v.$FG-Blue;  }

	.paginationPostCount {
		width: 80%;
		height: 40px;
		color: v.$FG-Blue;
		border: 1px solid #E3E3E3;
		padding: 10px;
		margin-left: 10px;
	}

	.paginateandcount {
		display: flex;
		align-items: center;
		justify-content: center;
	}

@use "variables" as v;

.footer-container{
    background-color: v.$FG-Blue;
    width: 98%;
    margin-left: 1%;
    padding: 1%;
    display: flex;
  
}

.footer-label{
    color: #f2f2f2;
}
@use "variables" as v;

.fb-login-container {
    display: flex;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 30px;
    width: 65%;
    height: 63%;
    margin-top: 150px;
    background-color: v.$white;
    -webkit-box-shadow: 0px 0px 20px -1px v.$black;
    -moz-box-shadow: 0px 0px 20px -1px v.$black;
    box-shadow: 0px 0px 20px -1px v.$black;
    z-index: 2;
    min-height: 450px;
    min-width: 800px;
}

.fb-form {
    flex: 1;
}

.fb-map {
    flex: 2;
}

.googleMap{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.logo {
    width: 50%;
}

.login_photo {
    width: 100%;
    height: 150vh !important;
    z-index: 1;
}

.username,
.password,
.verification {
    width: 90%;
}

#login-buttons{
     margin-right: 9%
}
/* Forgot Password Form */

.resetPass-form {
    border-radius: 10px;
    padding: 30px;
    width: 50%;
    margin-top: 150px;
    background-color: v.$white;
    -webkit-box-shadow: 0px 0px 20px -1px v.$black;
    -moz-box-shadow: 0px 0px 20px -1px v.$black;
    box-shadow: 0px 0px 20px -1px v.$black;
    z-index: 2;
    min-height: 450px;
    min-width: 800px;
}

.resetPass-header {
    width: 100%;
    padding: 5%;
    border: 2px solid #f2f2f2;

}

.resetPass-username {
    width: 100%;

}

.resetPass-button {
    float: right;
}

.resetPass-signIn {
    margin-left: 40%;
}

.login_photo {
    width: 100%;
    height: 100vh;
    z-index: 1;
}